import React, { Suspense, lazy, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Loader } from 'shared/components/spinner/spinner';
import Layout from 'shared/hoc/layout/component/layout.component';

// import containers, for which lazy loading is not needed, as they will be rendered for all users
import NotFound from 'shared/components/404/404';
import { State } from 'shared/interface';
import authService from 'shared/services/auth.service';
import RouteManager from 'shared/hoc/permission/routeManager';
import { PermissionList } from 'shared/hoc/permission/permissionEnum';

import DashboardContainer from 'features/dashboard/container/dashboardContainer';
import FutureTableContainer from 'features/futuretable/container/futuretableContainer';
import DashboardIframe from 'features/dashboardIframe/container/dashboardIframe';
import AllForecastBoxContainer from 'features/forecast/container/allForecastBoxContainer';
const Login = lazy(() => import('features/login/container/login'));
const ReportsList = lazy(() => import('features/forecast/container/reportsList'));
const MaterialContainer = lazy(() => import('features/material/container/materialContainerV1'));
const NewsContainer = lazy(() => import('features/news/container/newsContainer'));
const AllMaterialsContainer = lazy(() => import('features/material/container/allMaterialsContainer'));
const MaterialAlertContainer = lazy(() => import('features/alert/container/matrialAlertContainer'));
const NewsAlertContainer = lazy(() => import('features/alert/container/newsAlertContainer'));
const AllAlertContainer = lazy(() => import('features/alert/container/allNewsContainer'));
const MyMaterialContainer = lazy(() => import('features/material/container/myMaterialContainer'));
const AdminContainer = lazy(() => import('features/admin/container/adminContainer'));
const ForecastLayout = lazy(() => import('features/forecast/container/layout'));
const ForecastDetail = lazy(() => import('features/forecast/container/forecastDetail'));
// const MyMaterialCourseListContainer = lazy(() => import('features/material/container/myMaterialCourseListContainer'));
const SaveNewsContainer = lazy(() => import('features/news/container/saveNewsContainer'));
const CreatePassword = lazy(() => import('features/createPassword/container/createPassword'));
const ResetPassword = lazy(() => import('features/resetPassword/container/resetPassword'));
const AdvanceSearchContainer = lazy(() => import('features/material/container/advanceSearchContainer'));
const ForgotPasswordMessagePage = lazy(() => import('features/login/component/forgotPasswordMessagePage'));
const AllTendersContainer = lazy(() => import('features/tenders/container/allTendersContainer'));
const TenderWrapperContainer = lazy(() => import('features/tenders/container/tenderWrapperContainer'));
const TenderAlertContainer = lazy(() => import('features/alert/container/tenderAlertContainer'));
const Profile = lazy(() => import('features/profile/container/profile'));
const UpdateNews = lazy(() => import('features/news/container/updateNews'));
const UnderMaintainancePage = lazy(() => import('shared/components/underMaintainance/underMaintainance'));
const CostModalPage = lazy(() => import('features/costModal/container/costModalPage'));
// const ErrorPage = lazy(() => import('shared/components/errorBoundary/errorPage'));

const App: React.FC = () => {
	const { auth: isLoggedIn } = useSelector((state: State) => state.auth.authData);
	const location = useLocation();
	const navigate = useNavigate();
	const { isTenderRights } = authService.getUserData();

	const isMaintainance = process.env.REACT_APP_IS_UNDER_MAINTAINANCE;

	const [cookies, , removeCookie] = useCookies<any>([]);
	useEffect(() => {
		if (!isLoggedIn) {
			localStorage.clear();
			sessionStorage.clear();
			Object.keys(cookies).forEach((key: any) => {
				removeCookie(key);
			});
		}
	}, [cookies, isLoggedIn, isMaintainance, removeCookie]);

	useEffect(() => {
		if (location.hash !== '') {
			navigate('/404');
		}
	}, [location.hash, navigate]);

	if (isLoggedIn && isMaintainance === 'false') {
		return (
			<Layout>
				<Suspense fallback={<Loader />}>
					<Routes>
						{/* <Route path='/costModel' element={<CostModal />} /> */}
						<Route path='/costModal/:type/:id' element={<CostModalPage />} />
						<Route
							path='/materials'
							element={<RouteManager component={<MaterialContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/materials/allMaterials'
							element={<RouteManager component={<AllMaterialsContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/materials/search'
							element={<RouteManager component={<AdvanceSearchContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/materials/futureTable'
							element={<RouteManager component={<FutureTableContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/materials/chartScreen'
							element={<RouteManager component={<FutureTableContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/materials/myMaterial'
							element={<RouteManager component={<MyMaterialContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/materials/myMaterial/courseList'
							element={<RouteManager component={<MaterialContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/alert/material/:courseName'
							element={<RouteManager component={<MaterialAlertContainer />} permission={[PermissionList.MaterialStatus]} />}
						/>
						<Route
							path='/news/:section/:groupId'
							element={<RouteManager component={<NewsContainer />} permission={[PermissionList.NewsStatus]} />}
						/>
						<Route
							path='/update/news/:id'
							element={<RouteManager component={<UpdateNews />} permission={[PermissionList.NewsStatus]} />}
						/>
						<Route
							path='/news/saveNews'
							element={<RouteManager component={<SaveNewsContainer />} permission={[PermissionList.NewsStatus]} />}
						/>
						<Route
							path='/alert/news'
							element={<RouteManager component={<NewsAlertContainer />} permission={[PermissionList.NewsStatus]} />}
						/>
						{isTenderRights && (
							<>
								<Route path='/tenders/allTenders' element={<AllTendersContainer />} />
								<Route path='/tenders/:section/:rubrics' element={<TenderWrapperContainer />} />
							</>
						)}
						<Route path='/dashboard' element={<DashboardContainer />} />

						<Route
							path='/forecasts'
							element={<RouteManager component={<ForecastLayout />} permission={[PermissionList.ForecastStatus]} />}
						>
							<Route path='' element={<AllForecastBoxContainer />} />
							<Route path='list/:id' element={<ReportsList />} />
							<Route path='data/:id' element={<ForecastDetail />} />
							<Route path='data/:id/:forecastId' element={<ForecastDetail />} />
						</Route>
						<Route path='/profile' element={<Profile />} />
						<Route path='/profile:section' element={<Profile />} />
						<Route path='/admin' element={<AdminContainer />} />

						<Route path='/alert/tenders' element={<TenderAlertContainer />} />
						<Route path='/alert/:courseName' element={<AllAlertContainer />} />
						<Route path='/404' element={<NotFound />} />
						<Route path='*' element={<Navigate replace to='/dashboard' />} />
						<Route path='/iframe/:resolution/:id' element={<DashboardIframe />} />
						{/* <Route path='/error' element={<ErrorPage />} /> */}
					</Routes>
				</Suspense>
			</Layout>
		);
	} else if (isLoggedIn && isMaintainance === 'true') {
		return (
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route path='/underMaintainance' element={<UnderMaintainancePage />} />
					<Route path='*' element={<Navigate replace to='/underMaintainance' />} />
				</Routes>
			</Suspense>
		);
	} else {
		// lazy loading not needed for login, forget password, reset password APIs
		return (
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/iframe/:resolution/:id' element={<DashboardIframe />} />
					<Route path='/forgot-password' element={<ForgotPasswordMessagePage />} />
					<Route path='/aktivieren/:token' element={<CreatePassword />} />
					<Route path='/resetPassword/:token' element={<ResetPassword />} />
					<Route path='*' element={<Navigate replace to='/login' />} />
				</Routes>
			</Suspense>
		);
	}
};

export default App;

import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Chart, ZoomButtons } from 'react-stockcharts';
import { Label } from 'react-stockcharts/lib/annotation';
import { CrossHairCursor, CurrentCoordinate } from 'react-stockcharts/lib/coordinates';
// import { compare } from 'react-stockcharts/lib/indicator';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { AreaSeries, LineSeries } from 'react-stockcharts/lib/series';
// import { HoverTooltip } from 'react-stockcharts/lib/tooltip';
import * as d3 from 'd3';
import { scaleLinear, scaleLog } from 'd3-scale'; // Import D3 scaleLog
import { curveMonotoneX } from 'd3-shape';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
// import { fitWidth } from 'react-stockcharts/lib/helper';
import { last } from 'react-stockcharts/lib/utils';

import { getChartDataBasedOnIndicators, getIndicatorsWindowSize } from 'features/futuretable/util/chartUtility';
import { englishLocale, germanLocale } from 'shared/constants/constants';
import { State } from 'shared/interface';
import { ChartAxisPosition, ScaleType } from 'shared/interface/enums';
import {
	calculateAverages,
	chartTimeRangeMapper,
	createAction,
	customNumberFormatter,
	debounce,
	formatDate,
	germanNumberFormattter,
	getChartDataBasedOnSpan,
	renderTicks
} from 'shared/util/utility';

import { t } from 'i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { hexToRGBA } from 'react-stockcharts/lib/utils';
import ChartCanvas from 'features/futuretable/util/chartCanvas';
import compare from 'features/futuretable/util/compare';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import * as actionTypes from 'store/action-types';
import { IChartData, IChartDesignConfig, IChartDetails, IChartSettingsType, ISpan } from '../interface/futureTable';
import ChartAxis from './chartAxis';
import CommonChart from './CommonChart';
import ChangeChartColorPopup from './changeChartColorPopup';
import HoverTooltip from './hoverTooltip';
import HoverTooltipSVG from './hoverTooltipSVG';
import { RenderExternalIndicators } from './renderExternalIndicators';
import RenderIndicatorsOnchart from './renderIndicatorsOnchart';
import TooltipBackgroundShapeSVG from './tooltipBackgroundShapeSVG';

interface IProps {
	chartData: IChartData[];
	chartType: string;
	showZoomButton: boolean;
	loading: boolean;
	chartDetails: IChartDetails;
	cardIndex: number;
	chartDesignConfig: IChartDesignConfig;
	spanObj: ISpan;
	chartName: string;
	chartSettings: IChartSettingsType[];
	scaleType: string;
	isIntraday?: boolean;
	setChartColor?: (color: string) => void;
	materialIndex?: number;
	staticHeight?: boolean;
	allChartDetails: { [key: string]: IChartDetails };
	barWidth?: { [key: string]: number };
	setBarWidth?: (width: { [key: string]: number }) => void;
}

export interface ISTOIndicatorType {
	datum: number;
	tage: number;
	k: number;
}

export interface IMACDIndicatorInputType {
	kurz: number;
	signal: number;
	lang: number;
}

export interface IIndicatorConfig {
	keyName: string;
	indicatorKeyName: string;
	windowSize: number | ISTOIndicatorType | IMACDIndicatorInputType;
	value: string;
}

export interface IIndicatorObject {
	macd: {
		[key: string]: number;
	};
	sto: {
		[key: string]: number;
	};
}

const chartTopPadding = 50;
const StockChart: React.FC<IProps> = (props) => {
	const {
		chartData,
		chartType,
		spanObj: { span, endDate, startDate },
		chartDetails,
		cardIndex,
		chartDesignConfig,
		showZoomButton,
		chartSettings,
		scaleType,
		loading,
		setChartColor,
		chartName,
		materialIndex,
		staticHeight,
		allChartDetails,
		barWidth,
		setBarWidth
	} = props;

	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);
	const [suffix, setSuffix] = useState(0);
	const language = useSelector((state: State) => ({ ...state.siteConfig }.language));
	const [action, setAction] = useState('');
	const location = useLocation();
	const dispatch = useDispatch();
	const isIframe = location.pathname.includes('iframe');

	const chartContainerElement = document.getElementById(`chart-container-${chartName}-${chartDetails.symbol}-${cardIndex}`);

	const totalIndicatorsLength = useMemo(() => {
		return chartSettings.reduce((acc, chart) => {
			return acc + chart.indicators.filter((indi) => ['atr', 'rsi', 'sto', 'macd'].includes(indi.value)).length;
		}, 0);
	}, [chartSettings]);

	const calculateHeight = useMemo(() => {
		return (cardIndex < 2 ? 135 : 100) * totalIndicatorsLength;
	}, [cardIndex, totalIndicatorsLength]);

	const handleResize = useCallback(
		(entries: ResizeObserverEntry[]) => {
			if (!chartContainerElement) return;

			const { width, height } = entries[0].contentRect;

			// Only update state if there is a significant change in dimensions
			setContainerWidth((cw) => (width !== cw && Math.abs(cw - width) > 15 ? width : cw));

			setContainerHeight((ch) => (ch === 0 && height !== ch && Math.abs(ch - height) > 15 ? height : ch));
		},
		[chartContainerElement]
	);

	const throttleResizeFun = useCallback(debounce(handleResize, 50), [handleResize]);

	useEffect(() => {
		if (!chartContainerElement) return;

		const resizeObserver = new ResizeObserver(throttleResizeFun);
		resizeObserver.observe(chartContainerElement);

		return () => {
			resizeObserver.unobserve(chartContainerElement);
		};
	}, [chartContainerElement, throttleResizeFun]);

	const compareKeys = chartSettings.map((compareData, index) => {
		return `cv${compareData.symbol}-${compareData.boerse}-${materialIndex || index}`;
	});

	const indicatorsFunctionNames = [] as IIndicatorConfig[];
	chartSettings.map((chart, index) => {
		if (chart.indicators.length > 0) {
			chart.indicators.map((indi) => {
				indicatorsFunctionNames.push({
					indicatorKeyName: `${indi.value}-${chart.symbol}-${chart.boerse}-${chart?.id || ''}`,
					keyName: `${
						scaleType === ScaleType.PERCENTAGE_CHANGE
							? indi.value === 'atr' || indi.value === 'sto'
								? 'cv'
								: 'percentage'
							: indi.value === 'atr' || indi.value === 'sto'
							? 'cv'
							: 'price'
					}${chart.symbol}-${chart.boerse}-${index}`,
					windowSize: getIndicatorsWindowSize(indi),
					value: indi.value
				});
			});
		}
	});

	const compareCalculator = compare()
		.options({
			basePath: [...compareKeys, ...indicatorsFunctionNames.map((indi) => indi.indicatorKeyName)],
			mainKeys: [...compareKeys, ...indicatorsFunctionNames.map((indi) => indi.indicatorKeyName)],
			compareKeys: [...compareKeys, ...indicatorsFunctionNames.map((indi) => indi.indicatorKeyName)]
		})
		.accessor((d: any) => {
			d.compare;
		})
		.merge((d: any, c: any) => {
			d.compare = {
				...c
			};
		});

	const xScaleProvider = discontinuousTimeScaleProvider
		.inputDateAccessor((d: any) => {
			return moment(d.date).toDate();
		})
		.setLocale(language == 'en' ? englishLocale : germanLocale);

	const chartDataBasedOnType = useMemo(() => {
		return chartType === 'bar'
			? getChartDataBasedOnSpan(chartTimeRangeMapper(chartData)[span as string] as number, calculateAverages([...chartData]))
			: chartData;
	}, [chartData, chartType, span]);

	const calculatedData = useMemo(
		() => getChartDataBasedOnIndicators(compareCalculator(chartDataBasedOnType), indicatorsFunctionNames, scaleType),
		[chartDataBasedOnType, compareCalculator, indicatorsFunctionNames, scaleType]
	);
	const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData);
	location.pathname.includes('/dashboard') ? (cardIndex > 1 ? 3 : 10) : 23;

	useEffect(() => {
		const { symbol, boerse } = chartDetails;
		dispatch(createAction(actionTypes.GET_CHART_LAST_OBJ, { key: [`${symbol}-${boerse}`], data: calculatedData[calculatedData.length - 1] }));
	}, []);

	const getXextentForBarChart = useCallback(() => {
		switch (span) {
			case '1w':
				return xAccessor(data[Math.max(0, data.length - (location.pathname.includes('/dashboard') ? (cardIndex > 1 ? 3 : 10) : 24))]);
			case '1m':
				return xAccessor(data[Math.max(0, data.length - (location.pathname.includes('/dashboard') ? (cardIndex > 1 ? 3 : 10) : 18))]);
			case '1y':
				return location.pathname.includes('/dashboard') && cardIndex > 1 ? xAccessor(data[Math.max(0, data.length - 3)]) : xAccessor(data[0]);
			default:
				return xAccessor(data[0]);
		}
	}, [cardIndex, data, location.pathname, span, xAccessor]);

	const xExtents = useMemo(() => {
		if (startDate && endDate) {
			const startIndex = data.findIndex((ele: any) => formatDate(ele.date, 'DD/MM/YYYY') === formatDate(startDate, 'DD/MM/YYYY'));
			const endIndex = data.findIndex((ele: any) => formatDate(ele.date, 'DD/MM/YYYY') === formatDate(endDate, 'DD/MM/YYYY'));
			return [startIndex === -1 ? xAccessor(data[0]) : startIndex, endIndex === -1 ? xAccessor(last(data)) : endIndex];
		} else {
			const start = xAccessor(last(data));
			// const dateDiff = getStartDateForExport(span as string, data[start].date, data);

			// const endIndex = data.findIndex((element: any) => new Date(element.date).toDateString() === new Date(dateDiff).toDateString());
			const end = chartType === 'bar' ? getXextentForBarChart() : xAccessor(data[0]);
			// const end = xAccessor(data[0]);
			return [start, end];
		}
	}, [chartType, startDate, endDate, data, getXextentForBarChart, xAccessor]);

	const toolTipContent = (ys: any) => {
		moment.updateLocale(language, language === 'de' ? germanLocale : englishLocale);
		const month = moment(ys.currentItem.date).format('MMMM');
		const year = moment(ys.currentItem.date).format('YYYY');
		const currentDate = moment(ys.currentItem.date).format(props.isIntraday ? 'DD-MM-YYYY hh:mm A' : 'DD-MM-YYYY');
		const date =
			chartDetails.notierung === 'M' && chartType !== 'bar'
				? `${month}-${year}`
				: chartType === 'bar' && span === '1w'
				? `${t(localizationConstants.week)} ${ys.currentItem.week}`
				: chartType === 'bar' && span === '1m'
				? `${month}-${year}`
				: chartType === 'bar' && span === '1y'
				? `${year}`
				: currentDate;
		return {
			x: [`${date}`],
			y: props.chartSettings?.map((ele, index) => {
				const name =
					(language === 'en' &&
						allChartDetails[`${ele.symbol}-${ele.boerse}-${index}`]?.name_eng !== '' &&
						allChartDetails[`${ele.symbol}-${ele.boerse}-${index}`]?.name_eng) ||
					allChartDetails[`${ele.symbol}-${ele.boerse}-${index}`]?.name;
				const isShowUnitCurrency =
					ele.currency.value !== null && ele.currency.value !== '' && ele.unit.value !== null && ele.unit.value !== '';
				const currency = isShowUnitCurrency ? ele.currency.value : '';
				const unit = isShowUnitCurrency ? ele.unit.value : '';
				const showCurrencyUnit = isShowUnitCurrency ? `${currency} / ${unit}` : '';
				const showNameCurrencyUnit = isShowUnitCurrency ? `[${ele.currency.value} / ${ele.unit.value}]` : '';
				const isShowLabel = ys.currentItem.date === ys.currentItem[`cv${ele.symbol}-${ele.boerse}-${index}`]?.d || chartType === 'bar';
				const value = ys.currentItem[`cv${ele.symbol}-${ele.boerse}-${materialIndex || index}`]?.l;
				const finalPrice = value
					? `${customNumberFormatter(value, allChartDetails[`${ele.symbol}-${ele.boerse}-${index}`].nkstellen)} ${showCurrencyUnit}`
					: '';

				return {
					label: `${name} ${showNameCurrencyUnit}`,
					value: finalPrice,
					stroke: ele.color || '',
					indicator: ele.indicators
						.filter((i) => !['atr', 'rsi', 'macd', 'sto'].includes(i.value))
						.map((indi, index) => {
							const indiValue = ys.currentItem[`${indi.value}-${ele.symbol}-${ele.boerse}-${ele.id}`];
							const opacity = (100 - (index + 1) * 10) / 100;
							const indicatorColor = hexToRGBA(ele.color, opacity);
							return {
								label:
									indi.value === 'bb'
										? `${indi.value.toUpperCase()} (close, ${indi.calculatedValue}, sma)`
										: `${indi.value.toUpperCase()} (${indi.calculatedValue})`,
								value:
									indi.value === 'bb'
										? `${indiValue?.top ? customNumberFormatter(indiValue.top) : '     '} ${
												indiValue?.middle ? customNumberFormatter(indiValue?.middle) : '     '
										  } ${indiValue?.bottom ? customNumberFormatter(indiValue?.bottom) : '     '}`
										: indiValue
										? customNumberFormatter(indiValue)
										: '     ',
								stroke: indicatorColor
							};
						}),
					isShowLabel: props.isIntraday ? isShowLabel : true
				};
			})
		};
	};

	const handleChangeChartColor = (color: string) => {
		setChartColor && setChartColor(color);
		setAction('');
	};
	const isZoomEnabled = chartType !== 'bar';

	const getBarChartYAccsesor = useMemo((): { yAxisLeftAccsessor: any[]; yAxisRightAccessor: any[] } => {
		const barChartLeftAxisYAccsesor: any = [];
		const barChartRightAxisYAccsesor: any = [];
		chartSettings.map((chart, index) => {
			if (chart.axis.value === ChartAxisPosition.LEFT_AXIS) {
				barChartLeftAxisYAccsesor.push((d: any) => d[`cv${chart.symbol}-${chart.boerse}-${materialIndex || index}`]?.l);
			} else {
				barChartLeftAxisYAccsesor.push(() => NaN);
			}
			if (chart.axis.value === ChartAxisPosition.RIGHT_AXIS) {
				barChartRightAxisYAccsesor.push((d: any) => d[`cv${chart.symbol}-${chart.boerse}-${materialIndex || index}`]?.l);
			} else {
				barChartRightAxisYAccsesor.push(() => NaN);
			}
		});
		return { yAxisLeftAccsessor: barChartLeftAxisYAccsesor, yAxisRightAccessor: barChartRightAxisYAccsesor };
	}, [chartSettings]);

	const { leftAxisData, rightAxisData } = useMemo(
		() => ({
			leftAxisData: chartSettings.filter((ele) => ele.axis.value === ChartAxisPosition.LEFT_AXIS),
			rightAxisData: chartSettings.filter((ele) => ele.axis.value === ChartAxisPosition.RIGHT_AXIS)
		}),
		[chartSettings]
	);

	const getPercentageChangeYExtentKeys = useMemo(() => {
		const leftValues = leftAxisData.map((data) => {
			const findIndex = chartSettings.findIndex((chart) => chart.id === data.id);
			return `cv${data.symbol}-${data.boerse}-${materialIndex || findIndex}`;
		});
		const rightValues = rightAxisData.map((data) => {
			const findIndex = chartSettings.findIndex((chart) => chart.id === data.id);
			return `cv${data.symbol}-${data.boerse}-${materialIndex || findIndex}`;
		});
		return {
			leftAxisData: leftValues,
			rightAxisData: rightValues
		};
	}, [chartSettings, leftAxisData, rightAxisData]);

	const yScale = scaleType === ScaleType.LOGARITHAMIC_AXIS ? scaleLog() : scaleLinear();

	let isCompareWithSelfEnabled = false;
	chartSettings.map((chart: IChartSettingsType) => {
		if (chart.history.value !== '') {
			isCompareWithSelfEnabled = true;
		}
	});

	const finalHeight = useMemo(() => containerHeight + calculateHeight, [calculateHeight, containerHeight]);

	return (
		(chartDetails && !loading && (
			<Fragment key={chartDetails.symbol}>
				{!isEmpty(chartData) && data.length <= 1 && (
					<div className='d-flex justify-content-center align-items-center' style={{ height: finalHeight }}>
						<Translate text={localizationConstants.noEnoughDataToPlot} />
					</div>
				)}
				{!isEmpty(chartData) && data.length > 1 && containerWidth !== 0 && finalHeight !== 0 && (
					<ChartCanvas
						width={containerWidth}
						height={staticHeight ? 550 : finalHeight}
						seriesName={`MSFT_${suffix}`}
						data={data}
						type={'svg'}
						xAccessor={xAccessor}
						xScale={xScale}
						displayXAccessor={displayXAccessor}
						xExtents={xExtents}
						clamp={true}
						margin={chartDesignConfig.margin}
						postCalculator={compareCalculator}
						ratio={1}
						mouseMoveEvent={true}
						panEvent={!isIframe}
						zoomEvent={!(!isZoomEnabled || isIframe)}
						pointsPerPxThreshold={100}
						padding={
							!isEmpty(barWidth) && barWidth[`${chartDetails.symbol}-${chartDetails.boerse}`]
								? barWidth[`${chartDetails.symbol}-${chartDetails.boerse}`]
								: 0
						}
						className={`react-stockchart-${chartName}`}
						zoomMultiplier={span === '1w' ? 2 : 1.1}
						chartView={scaleType}
						useCrossHairStyleCursor={!isIframe}
					>
						<defs>
							{chartSettings.map((chart) => (
								<Fragment key={`${chart.id}-${cardIndex}`}>
									<linearGradient id={`bar${chart.id}-${cardIndex}`} x1='0%' y1='0%' x2='0%' y2='100%'>
										<stop offset='0%' stopColor={chart.color} />
										<stop offset='100%' stopColor={chart.color} stopOpacity='0' />
									</linearGradient>
									<linearGradient
										id={`MyGradient${chart.symbol}-${chart.boerse}${chart.id}-${cardIndex}`}
										x1='100%'
										y1='-50%'
										x2='100%'
										y2='100%'
										gradientUnits='userSpaceOnUse'
									>
										<stop offset='0.326661' stopColor={chart.color} />
										<stop offset='1' stopColor={chart.color} stopOpacity='0' />
									</linearGradient>
									<linearGradient id={'MyGradientForecast'} x1='100%' y1='-50%' x2='100%' y2='100%' gradientUnits='userSpaceOnUse'>
										<stop offset='0.326661' stopColor={'#FF9100'} />
										<stop offset='1' stopColor={'#FF9100'} stopOpacity='0' />
									</linearGradient>
								</Fragment>
							))}
						</defs>
						{chartName === 'main' && (
							<Label
								x={
									containerWidth -
									(size(chartSettings) > 1 &&
									!isEmpty(chartSettings.filter((item) => item.axis.value === 'left')) &&
									chartSettings.filter((item) => item.axis.value === 'left').length !== chartSettings.length
										? 220
										: 150)
								}
								y={containerHeight - 60}
								fontSize={25}
								text='MATFLIXX'
								fill='#507dcb'
							/>
						)}
						{(scaleType === ScaleType.PRICE_AXIS || scaleType === ScaleType.LOGARITHAMIC_AXIS) && !isEmpty(rightAxisData) && (
							<Chart
								id={`${chartDetails.symbol}-${chartDetails.boerse}-${cardIndex}-1`}
								yExtents={(d: any) => {
									const values = getPercentageChangeYExtentKeys.rightAxisData.map((key) => {
										return d[key]?.l;
									});
									return [...values];
								}}
								height={containerHeight - chartTopPadding}
								yScale={yScale}
							>
								<>
									{rightAxisData.length > 0 && (
										<ChartAxis
											tickFormat={(d: number) => germanNumberFormattter(d, chartDetails.nkstellen)}
											chartType={chartType}
											data={data}
											span={span as string}
											axisAt={ChartAxisPosition.RIGHT_AXIS}
											chartDesignConfig={chartDesignConfig}
											yTicks={renderTicks(cardIndex, chartType === 'bar', chartName).yTicks}
											xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
											isCompareWithSelfEnabled={isCompareWithSelfEnabled}
										/>
									)}
									{rightAxisData.length > 0 &&
										rightAxisData.map((ele) => {
											return (
												<CommonChart
													cardIndex={cardIndex}
													key={ele.id}
													chartSettings={chartSettings}
													chartDetails={chartDetails}
													chartType={chartType}
													ele={ele}
													getBarChartYAccsesor={getBarChartYAccsesor.yAxisRightAccessor}
													materialIndex={materialIndex}
													setBarWidth={setBarWidth}
												/>
											);
										})}
								</>
								{showZoomButton && chartType !== 'bar' && (
									<ZoomButtons
										onReset={() => {
											setSuffix(suffix + 1);
										}}
									/>
								)}
							</Chart>
						)}
						{(scaleType === ScaleType.PRICE_AXIS || scaleType === ScaleType.LOGARITHAMIC_AXIS) && !isEmpty(leftAxisData) && (
							<Chart
								id={`${chartDetails.symbol}-${chartDetails.boerse}-${cardIndex}-2`}
								yExtents={(d: any) => {
									const values = getPercentageChangeYExtentKeys.leftAxisData.map((key) => {
										return d[key]?.l;
									});
									return [...values];
								}}
								height={containerHeight - chartTopPadding}
								yScale={yScale}
							>
								<>
									{leftAxisData.length > 0 && (
										<ChartAxis
											tickFormat={(d: number) => germanNumberFormattter(d, chartDetails.nkstellen)}
											chartType={chartType}
											data={data}
											span={span}
											axisAt={ChartAxisPosition.LEFT_AXIS}
											chartDesignConfig={chartDesignConfig}
											yTicks={renderTicks(cardIndex, chartType === 'bar', chartName).yTicks}
											xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
											isCompareWithSelfEnabled={isCompareWithSelfEnabled}
										/>
									)}
									{leftAxisData.length > 0 &&
										leftAxisData.map((ele) => {
											return (
												<CommonChart
													cardIndex={cardIndex}
													key={ele.id}
													chartSettings={chartSettings}
													chartDetails={chartDetails}
													chartType={chartType}
													ele={ele}
													getBarChartYAccsesor={getBarChartYAccsesor.yAxisLeftAccsessor}
													materialIndex={materialIndex}
													setBarWidth={setBarWidth}
												/>
											);
										})}
								</>
							</Chart>
						)}
						{scaleType === ScaleType.PERCENTAGE_CHANGE && !isEmpty(leftAxisData) && (
							<Chart
								id={`${chartDetails.symbol}-${chartDetails.boerse}-${cardIndex}-3`}
								yExtents={(d: any) => {
									const values = getPercentageChangeYExtentKeys.leftAxisData.map((key) => {
										return d.compare[key]?.p;
									});
									return [...values];
								}}
								height={containerHeight - chartTopPadding}
							>
								<>
									{leftAxisData.length > 0 && (
										<ChartAxis
											tickFormat={d3.format('.2%')}
											chartType={chartType}
											data={data}
											span={span}
											axisAt={ChartAxisPosition.LEFT_AXIS}
											chartDesignConfig={chartDesignConfig}
											yTicks={renderTicks(cardIndex, chartType === 'bar', chartName).yTicks}
											xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
											isCompareWithSelfEnabled={isCompareWithSelfEnabled}
										/>
									)}
									{chartType !== 'bar' &&
										leftAxisData.map((ele) => {
											const findIndex = chartSettings.findIndex((chart) => chart.id === ele.id);
											return (
												<Fragment key={ele.id}>
													{chartDetails.isIntraday && (
														<RenderChart
															type={chartType}
															yAccessor={(d: any) => {
																return d.compare[`cv${ele.symbol}-${ele.boerse}-${materialIndex || findIndex}`]?.p;
															}}
															fill={`url(#MyGradient${ele.symbol}-${ele.boerse}${ele.id}-${cardIndex})`}
															stroke={ele.color}
														/>
													)}
													{!chartDetails.isIntraday && (
														<>
															<RenderChart
																type={chartType}
																yAccessor={(d: any) => {
																	const momentDate = moment(d.date);
																	const dateObj = momentDate.toDate(); // Converts to JavaScript Date object
																	if (dateObj <= new Date() || chartSettings.length > 1) {
																		return d.compare[
																			`cv${ele.symbol}-${ele.boerse}-${materialIndex || findIndex}`
																		]?.p;
																	}
																}}
																fill={`url(#MyGradient${ele.symbol}-${ele.boerse}${ele.id}-${cardIndex})`}
																stroke={ele.color}
															/>
															{chartSettings.length === 1 && (
																<RenderChart
																	type={chartType}
																	yAccessor={(d: any) => {
																		const currentDate = new Date();
																		currentDate.setDate(currentDate.getDate() - 1);
																		const momentDate = moment(d.date);
																		const dateObj = momentDate.toDate(); // Converts to JavaScript Date object
																		if (dateObj >= currentDate) {
																			return d.compare[
																				`cv${ele.symbol}-${ele.boerse}-${materialIndex || findIndex}`
																			]?.p;
																		}
																	}}
																	fill='url(#MyGradientForecast)' // Color for the first area
																	stroke={'#FF9100'}
																/>
															)}
														</>
													)}
													{ele.indicators.map((indi, index) => {
														return (
															<RenderIndicatorsOnchart
																key={`${ele.id}-${indi.value}`}
																index={index}
																type={indi.value}
																color={ele.color}
																yAccessor={(d: any) => {
																	return d.compare[`${indi.value}-${ele.symbol}-${ele.boerse}-${ele.id}`];
																}}
															/>
														);
													})}
												</Fragment>
											);
										})}
								</>
							</Chart>
						)}
						{scaleType === ScaleType.PERCENTAGE_CHANGE && !isEmpty(rightAxisData) && (
							<Chart
								id={`${chartDetails.symbol}-${chartDetails.boerse}-${cardIndex}-4`}
								yExtents={(d: any) => {
									const values = getPercentageChangeYExtentKeys.rightAxisData.map((key) => {
										return d.compare[key]?.p;
									});
									return [...values];
								}}
								height={containerHeight - chartTopPadding}
							>
								<>
									{rightAxisData.length > 0 && (
										<ChartAxis
											tickFormat={d3.format('.2%')}
											chartType={chartType}
											data={data}
											span={span}
											axisAt={ChartAxisPosition.RIGHT_AXIS}
											chartDesignConfig={chartDesignConfig}
											yTicks={renderTicks(cardIndex, chartType === 'bar', chartName).yTicks}
											xTicks={renderTicks(cardIndex, chartType === 'bar', chartName).xTicks}
											isCompareWithSelfEnabled={isCompareWithSelfEnabled}
										/>
									)}
									{chartType !== 'bar' &&
										rightAxisData.map((ele) => {
											const findIndex = chartSettings.findIndex((chart) => chart.id === ele.id);
											return (
												<Fragment key={ele.id}>
													{chartDetails.isIntraday && (
														<RenderChart
															type={chartType}
															yAccessor={(d: any) => {
																return d.compare[`cv${ele.symbol}-${ele.boerse}-${materialIndex || findIndex}`]?.p;
															}}
															fill={`url(#MyGradient${ele.symbol}-${ele.boerse}${ele.id}-${cardIndex})`}
															stroke={ele.color}
														/>
													)}
													{!chartDetails.isIntraday && (
														<>
															<RenderChart
																type={chartType}
																yAccessor={(d: any) => {
																	if (new Date(d.date) <= new Date() || chartSettings.length > 1) {
																		return d.compare[
																			`cv${ele.symbol}-${ele.boerse}-${materialIndex || findIndex}`
																		]?.p;
																	}
																}}
																fill={`url(#MyGradient${ele.symbol}-${ele.boerse}${ele.id}-${cardIndex})`}
																stroke={ele.color}
															/>
															{chartSettings.length === 1 && (
																<RenderChart
																	type={chartType}
																	yAccessor={(d: any) => {
																		const currentDate = new Date();
																		currentDate.setDate(currentDate.getDate() - 1);
																		if (new Date(d.date) >= currentDate) {
																			return d.compare[
																				`cv${ele.symbol}-${ele.boerse}-${materialIndex || findIndex}`
																			]?.p;
																		}
																	}}
																	fill='url(#MyGradientForecast)' // Color for the first area
																	stroke={'#FF9100'}
																/>
															)}
														</>
													)}
													{ele.indicators.map((indi, index) => {
														return (
															<RenderIndicatorsOnchart
																key={`${ele.id}-${indi.value}`}
																index={index}
																type={indi.value}
																color={ele.color}
																yAccessor={(d: any) => {
																	return d.compare[`${indi.value}-${ele.symbol}-${ele.boerse}-${ele.id}`];
																}}
															/>
														);
													})}
												</Fragment>
											);
										})}
								</>
							</Chart>
						)}
						{chartSettings.length > 0 &&
							chartSettings.map((chart, index) => {
								const externalChartIndicators = chart.indicators.filter((item) => ['macd', 'sto', 'atr', 'rsi'].includes(item.value));
								return externalChartIndicators.map((indi) => {
									return RenderExternalIndicators({
										indi,
										chart,
										cardIndex,
										chartType,
										chartDesignConfig,
										chartSettings,
										scaleType,
										index,
										data,
										span: span,
										isCompareWithSelfEnabled,
										chartName
									});
								});
							})}
						{!isIframe && <CrossHairCursor fontSize={chartDesignConfig.fontSize} strokeDasharray='Solid' />}
						{showZoomButton && (
							<HoverTooltip
								tooltipContent={toolTipContent}
								fontSize={chartDesignConfig.fontSize}
								fontFill='#081D34'
								fontFamily='Outfit'
								bgFill='transparent'
								backgroundShapeSVG={(data: any, item: any) => {
									return <TooltipBackgroundShapeSVG item={item} />;
								}}
								tooltipSVG={(data: any, content: any) => {
									return <HoverTooltipSVG data={data} content={content} />;
								}}
							/>
						)}
					</ChartCanvas>
				)}
				{action === 'openChartColorPopup' && (
					<ChangeChartColorPopup handleClose={() => setAction('')} handleSubmit={handleChangeChartColor} />
				)}
			</Fragment>
		)) || <></>
	);
};

export const RenderChart: React.FC<{ type: string; yAccessor: any; fill?: string; stroke: string; canvasGradient?: any }> = ({
	type,
	yAccessor,
	fill,
	stroke,
	canvasGradient
}) => {
	const location = useLocation();
	const isIframe = location.pathname.includes('iframe');
	return (
		<>
			{type === 'area' && (
				<>
					<AreaSeries
						yAccessor={yAccessor}
						fill={fill} // Color for the first area
						strokeWidth={1}
						stroke={stroke}
						interpolation={curveMonotoneX}
						canvasGradient={canvasGradient}
						clip={false}
					/>
					{!isIframe && <CurrentCoordinate yAccessor={yAccessor} fill={fill} />}
				</>
			)}
			{type === 'line' && (
				<>
					<LineSeries yAccessor={yAccessor} stroke={stroke} strokeWidth={2} />;
					{!isIframe && <CurrentCoordinate yAccessor={yAccessor} fill={stroke} />}
				</>
			)}
		</>
	);
};

// const StockChart = fitWidth(StockChResponsiveStockChart);

export default StockChart;
